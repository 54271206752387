/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useAuth} from "../../../../app/components/layout/auth";
import {Languages} from "./Languages";
import {toAbsoluteUrl} from "../../../helpers";
import {
  PROFILE_OVERVIEW,
  PROFILE_SETTINGS,
} from "../../../../app/mock/page_urls";
import fullNameGenerator from "../../../../app/helpers/nameGenerator";
import phoneFormat from "../../../../app/helpers/phoneFormat";
import {BASE_URL} from "../../../../app/api/URLs";
import {useIntl} from "react-intl";

const HeaderUserMenu: FC = () => {
  const {currentUser} = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();

  const {logout} = useAuth();

  return (
      <div
          className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
          data-kt-menu="true"
      >
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img className={"object-fit-cover"} alt="Logo"
                   src={currentUser?.data.avatar ? BASE_URL + currentUser?.data.avatar : toAbsoluteUrl("/media/avatars/blank.png")}/>
            </div>

            <div className="d-flex flex-column">
              <div className="fw-bolder d-flex align-items-center fs-5">
                {currentUser && (
                    <>
                      {fullNameGenerator(
                          currentUser?.data.first_name,
                          currentUser?.data.last_name
                      )}
                    </>
                )}
              </div>
              <a href={`tel:${currentUser && phoneFormat(currentUser?.data.phone)}`}
                 className="fw-bold text-muted text-hover-primary fs-7">
                {currentUser && phoneFormat(currentUser?.data.phone)}
              </a>
            </div>
          </div>
        </div>

        <div className="separator my-2"></div>

        <div className="menu-item px-5">
          <Link to={PROFILE_OVERVIEW} className="menu-link px-5">
            {intl.formatMessage({id: "Mening profilim"})}
          </Link>
        </div>

        <div className="separator my-2"></div>

        <Languages/>

        <div className="separator my-2"></div>

        <div className="menu-item px-5 my-1">
          <Link to={PROFILE_SETTINGS} className="menu-link px-5">
            {intl.formatMessage({id: "Sozlamalar"})}
          </Link>
        </div>

        <div className="menu-item px-5">
        <span onClick={() => {
          logout();
          navigate("/")
        }} className="menu-link px-5">
          {intl.formatMessage({id: "Chiqish"})}
        </span>
        </div>
      </div>
  );
};

export {HeaderUserMenu};
