import React from 'react'
import "./order.scss"
import {localTranslate} from "../../../../helpers";
import {useQuery} from "react-query";
import {getLandingPopularizationProcedures} from "../../../../api/_requests";
import {queryCacheOptions} from "../../../../mock/cacheOptions";
import {useIntl} from "react-intl";

export const OrderSection = () => {
  const {data: popularizationProceduresData} = useQuery("getLandingPopularizationProcedures", getLandingPopularizationProcedures, {...queryCacheOptions})

  const {formatMessage}=useIntl()

  return (
      <div className={"bg-gray-200 p-3"} id={"popularization-procedures"}>
        <div className="container">
          <div className={"text-center w-100 w-sm-100 w-md-75 w-lg-50 mx-auto mb-10"}>
            <h1 className={"display-6 my-5"}>{localTranslate("Ommalashtirish tartibi", "Процедура продвижения", "Popularization procedures")}</h1>
            <p className={"fs-5"}>{formatMessage({id: "order_desc"})}</p>
          </div>

          <div className={"desktop__stepper"}>
            {popularizationProceduresData?.results?.reverse().map((procedure, index) => (
                index % 2 === 0 ? <div className="row text-end row-gap-10" key={index}>
                  <div className="col-md-6 d-flex justify-content-end position-relative pe-10">
                    <div className="d-flex flex-column justify-content-end flex-grow-1 pb-10">
                      <h2 className="h1">{procedure?.title}</h2>
                      <p className="fs-5 w-75 ms-auto">
                        {procedure?.description}
                      </p>
                      {procedure?.buttons?.map((button, index) => (
                          <a key={index} href={button?.url} target={"_blank"} rel="noreferrer"
                             className={`btn btn-${button?.type} ms-auto btn-sm`}>{button?.name}</a>
                      ))}
                    </div>
                    <div className="position-absolute d-flex flex-column align-items-center"
                         style={{right: "-20px", top: "5px", height: "100%"}}>
                      <div className="w-20px h-20px bg-primary rounded-circle"></div>
                      <div
                          className="bg-primary w-3px"
                          style={
                            (popularizationProceduresData?.results.length - 1 > index)
                                ? {marginTop: "-10px", flexGrow: 1}
                                : undefined
                          }
                      ></div>
                    </div>
                  </div>
                </div> : <div className="row" key={index}>
                  <div className="col-md-6 offset-md-6 position-relative d-flex flex-column">
                    <div className="w-75 ms-10 d-flex flex-column justify-content-center flex-grow-1 pb-10">
                      <h2 className="h1">{procedure?.title}</h2>
                      <p className="fs-5">{procedure?.description}</p>
                      {procedure?.buttons?.map((button, index) => (
                          <a rel="noreferrer" href={button?.url} target={"_blank"}
                             className={`btn btn-${button?.type} ms-auto btn-sm`}>{button?.name}</a>
                      ))}
                    </div>
                    <div className="position-absolute d-flex flex-column align-items-center"
                         style={{left: "0px", top: "5px", height: "100%"}}>
                      <div className="w-20px h-20px bg-primary rounded-circle"></div>
                      <div
                          className="bg-primary w-3px"
                          style={
                            (popularizationProceduresData?.results.length - 1 > index)
                                ? {marginTop: "-10px", flexGrow: 1}
                                : undefined
                          }
                      ></div>
                    </div>
                  </div>
                </div>
            ))}

          </div>

          {/*mobile*/}
          <div className="mobile__stepper container">
            {
              popularizationProceduresData?.results.map((procedure, index) => (
                 <div className="row" key={index}>
                    <div className="col-md-6 offset-md-6 position-relative d-flex flex-column">
                      <div className="w-75 ms-10 d-flex flex-column justify-content-center flex-grow-1 pb-10">
                        <h2 className="h1">{procedure?.title}</h2>
                        <p className="fs-5">{procedure?.description}</p>
                        {procedure?.buttons?.map((button, index) => (
                            <a rel="noreferrer" key={index} href={button?.url} target={"_blank"}
                               className={`btn btn-${button?.type} me-auto btn-sm`}>{button?.name}</a>
                        ))}
                      </div>
                      <div className="position-absolute d-flex flex-column align-items-center"
                           style={{left: "0px", top: "5px", height: "100%"}}>
                        <div className="w-20px h-20px bg-primary rounded-circle"></div>
                        <div
                            className="bg-primary w-3px"
                            style={
                              (popularizationProceduresData?.results.length - 1 > index)
                                  ? {marginTop: "-10px", flexGrow: 1}
                                  : undefined
                            }
                        ></div>
                      </div>
                    </div>
                 </div>
              ))
            }
          </div>

        </div>
      </div>
  )
}
