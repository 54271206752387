import React from 'react'
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";
import {APP_STORE_URL, PLAY_STORE_URL} from "../../../../mock/constants";
import {useIntl} from "react-intl";

export const DownloadAppSection = () => {
  const {formatMessage} = useIntl()
  return (
      <div className={"bg-primary bg-gray-200 pt-10 px-5 pt-md-20 m-5 my-20 m-lg-10 my-lg-20 rounded-4"}>
        <div className={"container"}>
          <div className={"row"}>
            <div className={"col-md-6 text-white"}>
              <div className={"mb-10"}>
                <h1 className={"display-6 text-white"}>{formatMessage({id: "download_app_title"})}</h1>
                <p className={"fs-2"}>
                  {formatMessage({id: "download_app_desc"})}
                </p>
              </div>
              <div>
                <a href={APP_STORE_URL} target={"_blank"}>
                  <img alt={"App store logo"} src={toAbsoluteUrl("./media/download/app-store-light.svg")}
                       className={"me-2"}/>
                </a>

                <a href={PLAY_STORE_URL} target={"_blank"}>
                  <img alt={"Play Store logo"} src={toAbsoluteUrl("./media/download/google-play-light.svg")}/>
                </a>
              </div>
            </div>
            <div className={"col-md-6 mt-20 mt-md-0"}>
              <div className={"ms-0 ms-md-20"}>
                <img className={"w-100 h-auto ps-0 ps-md-20"} alt={"Phone mock"}
                     src={toAbsoluteUrl("./media/download/phone.png")}/>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
