import isValidUrl from "../../../../helpers/isValidUrl";
import {useQuery} from "react-query";
import {getLandingFooters} from "../../../../api/_requests";
import {queryCacheOptions} from "../../../../mock/cacheOptions";
import {localTranslate} from "../../../../helpers";
import {URL_FILTER_MANUALS} from "../../../../mock/page_urls";
import {APP_STORE_URL, EDUCATION_SITE_URL, PLAY_STORE_URL} from "../../../../mock/constants";
import {CREATOR_URL} from "../../../../api/URLs";
import {toAbsoluteUrl} from "../../../../../_metronic/helpers";

export const Footer = () => {
  const {data: footerData,  isSuccess: footerIsSuccess} = useQuery("getLandingFooters", getLandingFooters, {...queryCacheOptions})
  const pages = [URL_FILTER_MANUALS]
  const isDark = localStorage.getItem("kt_theme_mode_value") === "dark";
  const BASE_URL = "https://ommalashtirish.uz"

  if (!footerIsSuccess) {
    return <div></div>;
  }

  return (
      <div className={`${isDark ? "bg-light" : "bg-dark"}  py-20`}>
          <div className="container">
              <div className="row px-10 fs-5">
                  <div className="col-md-3">
                    <div className="d-flex gap-5 mb-5 align-items-center">
                      <a href={EDUCATION_SITE_URL} target="_blank" rel="noopener noreferrer">
                        <img
                            src="/media/logos/uzedu_logo.png"
                            className="w-40px h-auto"
                            alt={localTranslate(
                                "Maktab va maktabgacha ta'lim vazirligi logo",
                                "Логотип Министерство школьного и дошкольного образования",
                                "Ministry of School and Preschool Education logo"
                            )}
                        />
                      </a>


                      <img src="/media/logos/rtm_logo.png" className="w-40px h-auto"
                           alt={localTranslate("Respublika ta'lim markazi logo", "Логотип Республиканского образовательного центра", "Republican educational center logo")}/>
                    </div>
                    <h1 className={isDark ? "text-dark" : "text-light"}>Ommalashtirish.uz</h1>

                    <div className={"my-5"}>
                      <a href={APP_STORE_URL} target={"_blank"}>
                        <img alt={"App store logo"} className={"w-100px"}
                             src={toAbsoluteUrl("./media/download/app-store-light.svg")}/>
                      </a>

                      <a href={PLAY_STORE_URL} target={"_blank"}>
                        <img alt={"Play store logo"} className={"w-100px ms-2"}
                             src={toAbsoluteUrl("./media/download/google-play-light.svg")}/>
                      </a>
                    </div>
                  </div>
                {
                  footerData?.results?.map((footer, index) => (
                      <div key={index} className="col-md-3">
                        <h5 className={`${isDark ? "text-dark" : "text-light"} mb-3 fs-3`}>{footer.title}</h5>
                        <ul className="list-unstyled text-dark">
                          {
                            footer.links.map((link, index) => (
                                <li key={index}>
                                  {
                                    isValidUrl(link.url) ? (
                                        <a href={link.url}
                                           className={`${isDark ? "text-dark" : "text-light"} text-decoration-none`}>{link.name}</a>
                                    ) : (
                                        <a href={pages.includes(link.url) ? BASE_URL+link.url : `${BASE_URL+"#"+link.url}`}
                                           className={`${isDark ? "text-dark" : "text-light"} text-decoration-none`}>{link.name}</a>
                                    )
                                  }
                                </li>
                            ))
                          }
                        </ul>
                      </div>
                  ))
                }
              </div>
              <hr className="text-secondary"/>
              <div className="text-center mt-10">
                <a rel="noreferrer" href={CREATOR_URL} target={"_blank"}>
                  <img src="/media/logos/felix_logo.png" alt="Felix-ITS Logo" className="w-100px h-auto"/>
                  <p className={`${isDark ? "text-dark" : "text-light"}  mt-2 user-select-none`}>{localTranslate("Felix-ITS MCHJ tomonidan ishlab chiqilgan", "Разработано ООО «Felix-ITS»", "Developed by Felix-ITS LLC")}</p>
                </a>
              </div>
          </div>
      </div>
  );
};
